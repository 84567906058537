













import {Component, Vue} from 'vue-property-decorator';
import {StatusTarefa, StatusFaturaLookup} from '@/enums/StatusTarefa';

@Component({
    name: 'SelectStatusFinance',
})
export default class SelectStatusFinance extends Vue {

    public value: string = '';

    public get options(): StatusTarefa[] {
        return StatusFaturaLookup.lookup.filter( (status) => {
            return status.toString() !== StatusTarefa.BAIXA_MANUAL.toString() &&
                status.toString() !== StatusTarefa.DEFAULT.toString();
        });
    }
}
