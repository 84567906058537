












































import {Component, Prop, Vue} from 'vue-property-decorator';
import Filters from '@/utils/Filters';

Vue.use(Filters);

@Component({
    name: 'InvoiceDocuments',
})
export default class InvoiceDocuments extends Vue {

    @Prop()
    public rowData: any;

    public dialogVisible: boolean = false;

    public filterFormatCurrency = Vue.filter('formatCurrency');
    public formatBrDate = Vue.filter('formatBrDate');

    public documentoList: any = [];

    public openModal(): void {
        this.documentoList = [];
        this.dialogVisible = true;

        this.$nextTick(() => {
            const docResources = this.rowData.documentoFaturaResource;
            this.documentoList = [
                ...docResources.documentoFaturaCteList.filter((doc) => doc.hasOwnProperty('numeroDoc')),
                ...docResources.documentoFaturaNfseList.filter((doc) => doc.hasOwnProperty('numeroDoc')),
                ...docResources.documentoFaturaReciboList.filter((doc) => doc.hasOwnProperty('numeroDoc')),
            ];
        });
    }
}
