











































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {Company} from '@/models/Company';
import {FreigthListInfo} from '@/models/FreigthListInfo';
import {FileTransferType} from '@/enums/FileTransferType';
import {StatusFaturaLookup, StatusTarefa} from '@/enums/StatusTarefa';
import DownloadButton from '@/components/commons/DownloadButton.vue';
import SelectStatusFinance from '@/components/finance/SelectStatusFinance.vue';
import SelectFormaPagamento from '@/components/finance/SelectFormaPagamento.vue';
import FilterBy from '@/components/commons/FilterBy.vue';
import FilterByItem from '@/components/commons/FilterByItem.vue';
import {Range} from '@/models/Range';
import Filters from '@/utils/Filters';
import FinanceRowAction from '@/components/finance/FinanceRowAction.vue';
import {DateUtils} from '@/utils/DateUtils';
import {Utils} from '@/utils/Utils';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import {ChaveTn3} from '@/utils/ChaveTn3';
import {Authority} from '@/models/Authority';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

/*
* propriedade pra filtro de busca
*/
export class FinanceFormFilters {
    public show: boolean = false;
    public faturaId: string = '';
    public qtdParcelas: string = '';
    public clientePagadorCnpj: string = '';
    public clientePagadorRazaoSocial: string = '';
    public formaPagamentoId: string = '';
    public dataVencimento: string = '';
    public dataPagamento: string = '';
    public status: string = '';
    public valorTitulo: string = '';
    public valorTituloMin: string = '';
    public valorTituloMax: string = '';
    public numeroCteIni: string = '';
    public numeroCteMax: string = '';
    public numeroCte: string = '';
    public chaveCte: string = '';
}

@Component({
    name: 'Finance',
    components: {
        FinanceRowAction,
        SelectStatusFinance,
        SelectFormaPagamento,
        DownloadButton,
        FilterBy,
        FilterByItem,
        ErrorMessage,
    },
})

export default class Finance extends Vue {

    public $refs!: {
        table: any,
        drawer: any,
        formaPagamento: any,
        form: Form,
    };

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    // drawer
    public dialog: boolean = false;

    // filters
    public filterBrDate = Vue.filter('brDate');
    public filterCurrencyStringToNumber = Vue.filter('currencyStringToNumber');
    public filterFormatCurrency = Vue.filter('formatCurrency');

    /*
    * propriedade pra filtro de busca
    */
    public formFilters: FinanceFormFilters = new FinanceFormFilters();

    public formLabelWidth: any = '120px';

    public isLoading: boolean = false;

    public totalRecords: number = 0;

    /*
    * Altura default da tabela da listagem
    */
    public tableHeight: string = '300px';

    /**
     * Regras de validacao
     */
    public rules: any = {
        chaveCte: [
            {
                validator: (rule, value, callback) => {
                    if (Vue.prototype.$isEmpty(value)) {
                        this.setErrorMessage('');
                        callback();
                    }

                    if (this.validaChaveCte(value)) {
                        this.setErrorMessage('');
                        callback();
                    }
                },
            },
        ],
    };

    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'dataVencimento',
            type: 'asc',
        },
        page: 1,
        perPage: 50,
    };

    public columns = [
        {
            label: 'Fatura',
            sortable: true,
            width: '60px',
            field: 'faturaId',
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Título',
            width: '43px',
            field: 'numeroTitulo',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Pagador',
            width: '150px',
            field: 'clientePagadorRazaoSocial',
            type: 'string',
            sortable: false,
        },
        {
            label: 'Forma Pgto.',
            width: '80px',
            field: 'formaPagamentoDescricao',
            type: 'string',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Dt. Vencimento',
            width: '70px',
            field: 'dataVencimento',
            formatFn: this.formatDateFn,
            type: 'date',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: true,
        },
        {
            label: 'Dt. Pagamento',
            width: '60px',
            field: 'dataPagamento',
            formatFn: this.formatDateFn,
            type: 'date',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: false,
        },
        {
            label: 'à Pagar',
            field: 'valorTitulo',
            width: '80px',
            formatFn: this.formatMoneyFn,
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: false,
        },
        {
            label: 'Valor Pago',
            width: '80px',
            field: 'valorPago',
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: false,
        },
        {
            label: 'Situação',
            width: '100px',
            field: 'status',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Opções',
            width: '150px',
            field: 'actions',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
    ];

    public rows: FreigthListInfo[] = [] as FreigthListInfo[];

    /*
   * Exportacao para excel
   */
    public xlsx: FileTransferType = FileTransferType.EXCEL;

    public formatDateFn(param) {
        return param.value ? this.filterBrDate(param.value) : '-';
    }

    public formatMoneyFn(param) {
        return this.filterFormatCurrency(param.value);
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.formFilters.show = true;
                this.$refs.drawer.closeDrawer();
                this.onPageChange('1');
            } else {
                return false;
            }
        });
    }

    public rowStyleClassFn(row) {
        const rowDisable = this.isDisableRow(row.status);
        return rowDisable ? 'disableRow' : '';
    }

    public formatString(str, len = 50, reduce = 47): string {
        return Utils.formatSizeStringRow(str, len, reduce);
    }

    public updateParams(newProps): void {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
    }

    public onPageChange(params): void {
        this.updateParams({page: params.currentPage});
        this.loadItems();
    }

    public onPerPageChange(params): void {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
    }

    public onSortChange(params): void {
        if (params.length) {
            let fieldParams = params[0].field;
            // aqui tem que customizar o campo de ordenacao por causa do alias da query feita na consulta.
            // faturaId é um alias, entao manda ordenar pelo campo fatura.id
            if (fieldParams === 'faturaId') {
                fieldParams = 'fatura.id';
            }
            this.updateParams({
                sort: {
                    field: fieldParams,
                    type: params[0].type,
                },
            });
        }
        this.loadItems();
    }

    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.applyFilters();
        this.$axios
            .post(
                `/financeiro/list/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                const data = response.data || {};
                this.totalRecords = data.recordsFiltered;
                this.rows = data.resources || [];
            }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        }).finally(() => this.isLoading = false);
    }

    public getStatus(status: number): StatusTarefa {
        return StatusFaturaLookup?.lookup?.find((s) => s.value === status) || StatusTarefa.DEFAULT;
    }

    public isPago(status: number): boolean {
        return StatusFaturaLookup
        ?.lookup
        ?.find((s) => s.value === status)
            ?.isPago || false;
    }

    public isDisableRow(status: number): boolean {
        return StatusFaturaLookup
        ?.lookup
        ?.find((s) => s.value === status)
            ?.isDisableRow || false;
    }

    public packData(): any {
        return {idDocList: this.$refs.table?.selectedRows.map((row) => row.id) || []};
    }

    public packDataExport(): any {
        return {
            tituloResource: this.serverParams.columnFilters,
            idDocList: this.$refs.table?.selectedRows.map((row) => row.id) || [],
        };
    }

    public getFiltersToExport(): any {
        return this.serverParams;
    }

    public onPackDownload(event: Event) {
        if (!this.packData().idDocList.length) {
            return true;
        }
        return false;
    }

    public calcPositionTable(): void {
        if (this.$refs.table != null) {
            const tableHeader = document.getElementsByClassName('vgt-fixed-header');
            const tableFooter = document.getElementsByClassName('vgt-wrap__footer');
            const headerPos = tableHeader[0].getBoundingClientRect();
            const footerPos = tableFooter[0].getBoundingClientRect();
            const calc = footerPos.top - (headerPos.bottom - headerPos.height);
            this.tableHeight = calc.toFixed(0);
        }
    }

    public getFixedHeigthTable(): string {
        return `${this.tableHeight}px`;
    }

    public cancelForm(): void {
        this.dialog = false;
    }

    /**
     * abre formulario de pesquisa
     */
    public openFormPesq(): void {
        this.setErrorMessage('');
        this.applyMask();
    }

    public validaChaveCte(param): boolean {
        if (Vue.prototype.$isEmpty(param)) {
            this.setErrorMessage('');
            return false;
        }
        if (ChaveTn3.isCte(param) === false) {
            this.setErrorMessage('Chave do CTe-e não é valida!');
            return false;
        } else {
            this.setErrorMessage('');
        }

        return true;
    }

    /**
     * Mascara de formatacao
     */
    public applyMask() {
        const im = new Inputmask('99/99/9999');
        const elDateVencInit = document.getElementById('dtvenc-range-start')!;
        const elDateVencEnd = document.getElementById('dtvenc-range-end')!;
        const elDatePagtoInit = document.getElementById('dtpagto-range-start')!;
        const elDatePagtoEnd = document.getElementById('dtpagto-range-end')!;
        im.mask(elDateVencInit);
        im.mask(elDateVencEnd);
        im.mask(elDatePagtoInit);
        im.mask(elDatePagtoEnd);
    }

    /**
     * Reset filtros de pesquisa
     */
    public cleanAll(): void {
        this.formFilters.show = false;
        this.onRefreshClick();
    }

    /**
     * Descricao da forma de pagamento para a descricao do filters quando
     * o usuario pesquisar
     */
    public formaPagtoDesc(): string {
        const formaPagtoList = this.$refs.formaPagamento.getFormaPagtoList()!;
        let desc = '';
        formaPagtoList.forEach((forma) => {
            if (forma.id === this.formFilters.formaPagamentoId) {
                desc = forma.descricao;
            }
        });
        return desc;
    }

    /**
     * Formata cnpj e razao para filtro de pesquisa
     */
    public joinCnpjNomeCliente(): string {
        const cnpj = this.formFilters.clientePagadorCnpj;
        const razao = this.formFilters.clientePagadorRazaoSocial;
        return Utils.joinCnpjRazao(cnpj, razao);
    }

    /**
     * Ajusta valores caso o usuario informe um valor maior no
     * campo onde deveria ser menor
     */
    public corrigeFilterValorTitulo(): void {
        const min = this.formFilters.valorTituloMin;
        const max = this.formFilters.valorTituloMax;
        if (min !== '' && max !== '') {
            const minVal = this.filterCurrencyStringToNumber(min);
            const maxVal = this.filterCurrencyStringToNumber(max);
            if (minVal > maxVal) {
                this.formFilters.valorTituloMin = max;
                this.formFilters.valorTituloMax = min;
            }
        } else if (min !== '' && max === '') {
            this.formFilters.valorTituloMin = min;
            this.formFilters.valorTituloMax = min;
        } else if (min === '' && max !== '') {
            this.formFilters.valorTituloMin = max;
            this.formFilters.valorTituloMax = max;
        }
    }

    /**
     * Ajusta valores caso o usuario informe um valor maior no
     * campo onde deveria ser menor
     */
    public corrigeFilterNumeroCte(): void {
        const min = this.formFilters.numeroCteIni;
        const max = this.formFilters.numeroCteMax;
        if (min !== '' && max !== '') {
            if (min > max) {
                this.formFilters.numeroCteIni = max;
                this.formFilters.numeroCteMax = min;
            }
        } else if (min !== '' && max === '') {
            this.formFilters.numeroCteIni = min;
            this.formFilters.numeroCteMax = min;
        } else if (min === '' && max !== '') {
            this.formFilters.numeroCteIni = max;
            this.formFilters.numeroCteMax = max;
        }
    }

    /**
     * Verifica valores informados para o valorTitulo na pesquisa
     */
    public getValorTituloPesq(): any {
        if (this.formFilters.valorTituloMin && this.formFilters.valorTituloMax) {
            this.corrigeFilterValorTitulo();
            return new Range(this.filterCurrencyStringToNumber(this.formFilters.valorTituloMin),
                this.filterCurrencyStringToNumber(this.formFilters.valorTituloMax));
        }
    }

    /**
     * Verifica valores informados para o numeroCte na pesquisa
     */
    public getNumeroCtePesq(): any {
        if (this.formFilters.numeroCteIni && this.formFilters.numeroCteMax) {
            this.corrigeFilterNumeroCte();
            return new Range(this.formFilters.numeroCteIni, this.formFilters.numeroCteMax);
        }
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    /*
    * aplica os filtros de busca por item da página
    */
    private applyFilters() {
        this.serverParams.columnFilters = Object.assign(
            {},
            {
                faturaId: this.formFilters.faturaId || null,
                qtdParcelas: this.formFilters.qtdParcelas || null,
                clientePagadorCnpj: this.formFilters.clientePagadorCnpj || null,
                clientePagadorRazaoSocial: this.formFilters.clientePagadorRazaoSocial || null,
                formaPagamentoId: this.formFilters.formaPagamentoId || null,
                dataVencimento: this.formFilters.dataVencimento ?
                    new Range(
                        this.formFilters.dataVencimento[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataVencimento[1] + DateUtils.getMinAndMaxTime().max) : null,
                dataPagamento: this.formFilters.dataPagamento ?
                    new Range(
                        this.formFilters.dataPagamento[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataPagamento[1] + DateUtils.getMinAndMaxTime().max) : null,
                valorTitulo: this.getValorTituloPesq() ? this.getValorTituloPesq() : null,
                numeroCte: this.getNumeroCtePesq() ? this.getNumeroCtePesq() : null,
                chaveCte: this.formFilters.chaveCte || null,
                status: typeof this.formFilters.status === 'number' ? this.formFilters.status : null,
            },
        );
    }

    private onRefreshClick(): void {
        this.formFilters = new FinanceFormFilters();
        this.serverParams = {
            columnFilters: {},
            page: 1,
            perPage: 50,
        };
        this.$refs.table.$refs.paginationBottom.currentPage = 1;
        this.$refs.table.reset();
    }

    private hasPermission() {
        return this.hasAuthOnCurrentCompany(this.$route.meta.permission, this.currentCompany);
    }

    private beforeMount(): void {
        if (this.isAuthenticated) {
            this.updateAuthorities();
        }
        if (!this.hasPermission()) {
            this.$router.push('dashboard');
        }
    }

    private mounted() {
        this.$nextTick(() => {
            this.calcPositionTable();
        });
        window.addEventListener('resize', this.calcPositionTable);
    }

    private unmounted() {
        window.removeEventListener('resize', this.calcPositionTable);
    }
}

