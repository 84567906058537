











import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'SelectFormaPagamento',
})

export default class SelectFormaPagamento extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    public value: string = '';

    public formaPagto: any = [];

    public mounted() {
        this.getFormaPagtoFromHermes();
    }

    public async getFormaPagtoFromHermes() {
        await this.$axios
            .get(`financeiro/formapagamento/${this.currentCompany.id}`)
            .then((response) => {
                if (response.data) {
                    this.setFormaPagamentoList(response.data);
                }
            }).catch((error) => {
                console.log('error', error);
                this.$toast.error('Erro ao consultar lista de formas de pagamento');
            });
    }

    public setFormaPagamentoList(formaPagamentoList: object): void {
        this.formaPagto = formaPagamentoList;
    }

    public getFormaPagtoList() {
        return this.formaPagto || [];
    }
}
