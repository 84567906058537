



























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import DownloadButton from '@/components/commons/DownloadButton.vue';
import {FileTransferType} from '@/enums/FileTransferType';
import {StatusFaturaLookup} from '@/enums/StatusTarefa';
import InvoiceDocuments from '@/components/finance/InvoiceDocuments.vue';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'FinanceRowAction',
    components: {InvoiceDocuments, DownloadButton},
})
export default class FinanceRowAction extends Vue {

    public $refs!: {
        invoicedocument: any,
    };

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public rowData: any;

    public baseUrl: string = '/financeiro/download';

    public pdf: FileTransferType = FileTransferType.PDF;
    public zip: FileTransferType = FileTransferType.ZIP;

    public openModal(): void {
        this.$refs.invoicedocument.openModal();
    }

    public isPago(status: number): boolean {
        return StatusFaturaLookup
        ?.lookup
        ?.find((s) => s.value === status)
            ?.isPago || false;
    }
}
